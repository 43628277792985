import { createRouter, createWebHistory } from 'vue-router'

import App from '@/main.js'
import Auth from './auth'
import Logs from './logs'
import Misc from './misc'
import General from './general'
import Testing from './testing'
import Cms from './cms'
import Devops from './devops'
import Admin from './admin'
import Pandora from './pandora'
// import Templates from './templates'
import Dms from './dms'
import Sports from './sports'
import Koala from './koala'
import Widgets from './widgets'
import Reports from './reports'

// import the store
import { store } from '../store/index'

function hasUnsavedChanges() {
  const initFormObject = store.getters['dynamicForm/getInitFormObject']
  const formObject = store.getters['dynamicForm/getFormObject']
  const formSubmitted = store.getters['dynamicForm/getFormSubmitted']
  // check initFormObject is empty, since 2000ms delay is added to setInitFormObject

  return (
    !formSubmitted &&
    initFormObject && // Check that initFormObject is not null or undefined
    Object.keys(initFormObject).length &&
    JSON.stringify(initFormObject) !== JSON.stringify(formObject)
  )
}

const tableModuleNames = [
  'data-table',
  'hreflang-table',
  'custom-hreflang-table',
  'model-select-items-selected',
  'model-select-items-selector',
]

export const routes = [
  ...Auth,
  ...Logs,
  ...Misc,
  ...General,
  ...Devops,
  ...Admin,
  // ...Templates,
  ...Dms,
  ...Sports,
  ...Koala,
  ...Cms,
  ...Widgets,
  ...Testing,
  ...Reports,
  ...Pandora,
  {
    path: '/:notFound(.*)',
    name: 'errorPage',
    meta: {
      auth: true,
      name: 'Page not found',
    },
    component: () => import('../components/atomic/pages/misc/errorPage.vue'),
  },
]

export const router = createRouter({
  history: createWebHistory(),
  routes,
  linkExactActiveClass: 'active',
})

router.beforeEach((to, from, next) => {
  if (hasUnsavedChanges()) {
    if (confirm('You have unsaved changes. Are you sure you want to leave?')) {
      store.commit('dynamicForm/setFormSubmitted', true)
      store.commit('dynamicForm/setFormObject', {})
      store.commit('dynamicForm/setInitFormObject', {})
      next()
    } else {
      next(false)
      return
    }
  }

  store.commit('dynamicForm/setFormSubmitted', true)
  store.commit('dynamicForm/setFormObject', {})
  store.commit('dynamicForm/setInitFormObject', {})

  const fromBaseRoute = from.path.split('/')[1]
  const toBaseRoute = to.path.split('/')[1]

  const fromSubRoute = from.path.split('/')[3]
  const toSubRoute = to.path.split('/')[3]

  if (fromBaseRoute === toBaseRoute && from.params.id === to.params.id && fromSubRoute !== toSubRoute) {
    // Clear queries and reset pagination
    store.dispatch('data-table/setActivePage', 1)
    store.dispatch('data-table/setMemorizedQueries', false)
    store.dispatch('data-table/clearQueries')
  }

  if (from.params.id && to.params.id) {
    const isNestedRoute =
      from.path.startsWith('/operators') &&
      to.path.startsWith('/operators') &&
      from.params.id === to.params.id

    if (!isNestedRoute) {
      tableModuleNames.forEach((moduleName) => {
        if (store.hasModule(moduleName)) {
          store.dispatch(`${moduleName}/clearQueries`)
        }
      })
    }
  }

  if (store.hasModule('data-table')) {
    if (fromBaseRoute !== toBaseRoute) {
      store.dispatch('data-table/clearQueries')
      store.dispatch('data-table/setMemorizedQueries', false)
    }

    if (!from.params.id && to.params.id) {
      store.dispatch('data-table/setMemorizedQueries', true)
      store.dispatch('data-table/clearQueries')
    } else if (from.params.id && !to.params.id) {
      store.dispatch('data-table/setQueriesFromMemorized')
    }
  }

  if (to.params.id) {
    store.commit('data-table/setCurrentRoute', to.path)
  }

  if (to.meta.permission) {
    const permissions = Array.isArray(to.meta.permission) ? to.meta.permission : [to.meta.permission]

    const user = App.config.globalProperties.$auth.user()
    const hasPermissions = user && permissions.every((perm) => user.permissions.includes(perm))

    if (!hasPermissions && !to.href === '/operators') {
      next({ name: 'Dashboard' })
      return
    }
  }

  next()
})
