export default {
  getForm() {
    return [
      {
        name: 'name',
        required: true,
        display_name: 'Name',
        type: 'text',
        slug_field: 'short_name',
      },
      {
        name: 'short_name',
        required: true,
        display_name: 'Short Name',
        type: 'slug',
        disabled: true,
      },
      {
        name: 'provider_id',
        search_url: 'hercules/games/software',
        required: true,
        display_name: 'Provider',
        type: 'list-one',
        value_key: 'software.name',
        trackBy: 'id',
        selectLabel: 'name',
      },
      {
        name: 'game_categories',
        search_url: 'hercules/globals/game-categories',
        display_name: 'Game Categories',
        display_field: 'name',
        type: 'list-many',
        fetch_on_load: false,
        validate_changes: true,
        required: true,
        only_create: true,
      },
      {
        name: 'game_categories',
        search_url: 'hercules/globals/game-categories',
        display_name: 'Game Categories',
        display_field: 'name',
        type: 'list-many',
        fetch_on_load: false,
        validate_changes: true,
        required: false,
        only_edit: true,
      },
      {
        name: 'average_rating',
        required: true,
        display_name: 'Ratings',
        sanitize_label: false,
        type: 'average_number',
        inputs: ['first_rating', 'second_rating', 'third_rating', 'fourth_rating'],
        // labelIndex: 0,
        average_input: 'rating',
        // showAverage: false,
        min: 0,
        max: 5,
        step: 0.1,
        only_create: true,
      },
      {
        name: 'average_rating',
        required: false,
        display_name: 'Ratings',
        sanitize_label: false,
        type: 'average_number',
        inputs: ['first_rating', 'second_rating', 'third_rating', 'fourth_rating'],
        // labelIndex: 0,
        average_input: 'rating',
        // showAverage: false,
        min: 0,
        max: 5,
        step: 0.1,
        only_edit: true,
      },
      {
        name: 'standardised_logo_asset_id',
        required: false,
        display_name: 'Standardised Logo',
        help_text:
          'Standardised logos must be requested from the design team and uploaded by a Hercules admin',
        type: 'base_media',
        substitute: 'image',
        permission: 'misc.games/manage-standardised-logos',
        standardised: true,
      },
      {
        name: 'volatility',
        required: true,
        display_name: 'Volatility',
        type: 'radio',
        read_only: false,
        values: [
          { value: 0, label: 'Low' },
          { value: 1, label: 'Medium' },
          { value: 2, label: 'High' },
        ],
        only_create: true,
      },
      {
        name: 'volatility',
        required: false,
        display_name: 'Volatility',
        type: 'radio',
        read_only: false,
        values: [
          { value: 0, label: 'Low' },
          { value: 1, label: 'Medium' },
          { value: 2, label: 'High' },
        ],
        only_edit: true,
      },
      {
        name: 'iframe',
        required: false,
        display_name: 'Iframe link',
        type: 'text',
      },
      {
        name: 'thumbnail_asset_id',
        required: false,
        display_name: 'Thumbnail',
        type: 'base_media',
      },
      {
        name: 'launch_date',
        required: false,
        display_name: 'Launch Date',
        type: 'date',
        read_only: false,
      },
      {
        name: 'rtp',
        required: true,
        display_name: 'Return to Player',
        type: 'custom-number',
        step: '0.01',
        custom_number_input: true,
        only_create: true,
      },
      {
        name: 'rtp',
        required: false,
        display_name: 'Return to Player',
        type: 'custom-number',
        step: '0.01',
        custom_number_input: true,
        only_edit: true,
      },
      {
        name: 'number_of_reels',
        required: true,
        display_name: 'Number of Reels',
        type: 'number',
        step: '1',
        only_create: true,
      },
      {
        name: 'number_of_reels',
        required: false,
        display_name: 'Number of Reels',
        type: 'number',
        step: '1',
        only_edit: true,
      },
      {
        name: 'winning_lines',
        required: true,
        display_name: 'Winning Lines',
        type: 'text',
        only_create: true,
      },
      {
        name: 'winning_lines',
        required: false,
        display_name: 'Winning Lines',
        type: 'text',
        only_edit: true,
      },
      {
        name: 'minimum_bet',
        required: true,
        display_name: 'Minimum bet',
        type: 'text',
        only_create: true,
      },
      {
        name: 'minimum_bet',
        required: false,
        display_name: 'Minimum bet',
        type: 'text',
        only_edit: true,
      },
      {
        name: 'maximum_bet',
        required: true,
        display_name: 'Maximum bet',
        type: 'text',
        only_create: true,
      },
      {
        name: 'maximum_bet',
        required: false,
        display_name: 'Maximum bet',
        type: 'text',
        only_edit: true,
      },
      {
        name: 'maximum_win',
        required: true,
        display_name: 'Maximum win',
        type: 'text',
        only_create: true,
      },
      {
        name: 'maximum_win',
        required: false,
        display_name: 'Maximum win',
        type: 'text',
        only_edit: true,
      },
      {
        name: 'hit_frequency',
        required: false,
        display_name: 'Hit frequency',
        type: 'text',
      },
      {
        name: 'technology',
        required: true,
        display_name: 'Technology',
        type: 'list-one',
        options: ['JS', 'HTML5', 'JS and HTML5', 'Blockchain', 'Streaming'],
        only_create: true,
      },
      {
        name: 'technology',
        required: false,
        display_name: 'Technology',
        type: 'list-one',
        options: ['JS', 'HTML5', 'JS and HTML5', 'Blockchain', 'Streaming'],
        only_edit: true,
      },
      {
        name: 'currencies',
        required: false,
        display_name: 'Crypto Currencies',
        type: 'list-many',
        search_url: 'hercules/globals/currencies?crypto=1&with_no_assets=1',
        selectLabel: 'name',
        help_text:
          'Only add crypto currencies for crypto games. Regular currencies should come from the operator currencies.',
        fetch_on_load: false,
        validate_changes: true,
      },
      {
        name: 'themes',
        required: false,
        display_name: 'Theme',
        type: 'list-many',
        search_url: 'hercules/games/themes',
        selectLabel: 'name',
        fetch_on_load: false,
        validate_changes: true,
      },
      {
        name: 'number_of_rows',
        display_name: 'Number of rows',
        type: 'number',
        step: '1',
      },
      {
        name: 'autoplay',
        required: false,
        display_name: 'Autoplay',
        type: 'checkbox',
      },
      {
        name: 'bonus_rounds',
        required: false,
        display_name: 'Bonus Rounds',
        type: 'checkbox',
      },
      {
        name: 'exclusive',
        required: false,
        display_name: 'Exclusive',
        type: 'checkbox',
      },
      {
        name: 'freespins',
        required: false,
        display_name: 'Freespins',
        type: 'checkbox',
      },
      {
        name: 'fruit',
        required: false,
        display_name: 'Fruit',
        type: 'checkbox',
      },
      {
        name: 'gamble',
        required: false,
        display_name: 'Gamble',
        type: 'checkbox',
      },
      {
        name: 'hot',
        required: false,
        display_name: 'Hot',
        type: 'checkbox',
      },

      {
        name: 'mega_ways',
        required: false,
        display_name: 'Mega Ways',
        type: 'checkbox',
      },
      {
        name: 'mobile_friendly',
        required: false,
        display_name: 'Mobile friendly',
        type: 'checkbox',
      },
      {
        name: 'multiplier',
        required: false,
        display_name: 'Multiplier',
        type: 'checkbox',
      },
      {
        name: 'new',
        required: false,
        display_name: 'New',
        type: 'checkbox',
      },
      {
        name: 'recommended',
        required: false,
        display_name: 'Recommended',
        type: 'checkbox',
      },
      {
        name: 'respins',
        required: false,
        display_name: 'Re Spins',
        type: 'checkbox',
      },
      {
        name: 'scatters',
        required: false,
        display_name: 'Scatters',
        type: 'checkbox',
      },
      {
        name: 'sticky_wilds',
        required: false,
        display_name: 'Sticky Wilds',
        type: 'checkbox',
      },
      {
        name: 'vegas',
        required: false,
        display_name: 'Vegas',
        type: 'checkbox',
      },
      {
        name: 'wilds',
        required: false,
        display_name: 'Wilds',
        type: 'checkbox',
      },
      {
        name: 'jackpots',
        required: false,
        display_name: 'Jackpot',
        type: 'checkbox',
        default: 0,
      },
      {
        name: 'buy_spins',
        required: false,
        display_name: 'Buy Spins',
        type: 'checkbox',
        default: 0,
      },
      {
        name: 'jackpot_type',
        display_name: 'jackpot_type',
        required: true,
        type: 'list-one',
        options: ['fixed', 'progressive', 'unknown(null)'],
        trackBy: null,
        selectLabel: null,
        show_if: { field: 'jackpots', value: [true, 1] },
      },
      {
        name: 'demo_link',
        required: false,
        type: 'text',
      },
      {
        name: 'paylines',
        required: false,
        type: 'text',
      },
      {
        name: 'game_description',
        required: false,
        type: 'text',
      },
      {
        name: 'land_based',
        required: false,
        type: 'radio',
        values: [
          { value: 1, label: 'Yes' },
          { value: 0, label: 'No' },
          { value: 2, label: 'Unset' },
        ],
      },
      {
        name: 'cluster_slot',
        required: false,
        type: 'radio',
        values: [
          { value: 1, label: 'Yes' },
          { value: 0, label: 'No' },
          { value: 2, label: 'Unset' },
        ],
      },
      {
        name: 'max_exposure',
        required: false,
        type: 'number',
      },
      {
        name: 'cascading_reels',
        required: false,
        display_name: 'Tumbling/Cascading Reels',
        type: 'radio',
        values: [
          { value: 1, label: 'Yes' },
          { value: 0, label: 'No' },
          { value: 2, label: 'Unset' },
        ],
      },
      {
        name: 'orientation',
        required: false,
        type: 'list-one',
        options: ['Portrait', 'Landscape'],
      },
      {
        name: 'max_win_per_spin',
        required: false,
        type: 'number',
      },
      {
        name: 'languages',
        required: false,
        type: 'text',
      },
      {
        name: 'real_money_markets',
        required: false,
        type: 'text',
      },
      {
        name: 'restrictions',
        required: false,
        type: 'tag',
      },
      {
        name: 'published',
        required: false,
        type: 'radio',
        values: [
          { value: 1, label: 'Yes' },
          { value: 0, label: 'No' },
          { value: 2, label: 'Unset'}
        ],
      },
    ]
  },
  getConfig() {
    return {
      url: 'games/games',
      name: 'Game',
      pagination: true,
    }
  },
  getStructure() {
    return {
      list: this.getForm(),
      config: this.getConfig(),
    }
  },
}
