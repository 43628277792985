<template>
  <div class="text-center" v-if="isLoading">
    <span>Loading...</span>
  </div>
  <div class="card" v-else>
    <dynamic-form
      ref="form"
      form-type="edit"
      :formFields="structure.list"
      :structure="structure"
      :object="object"
      :on-submit="submit"
      :path="config.url"
      :inCard="true"
      :on-remove="remove"
      :extra-item-title="operator.name"
    />
  </div>
</template>

<script setup>
import { ref, reactive, watch, getCurrentInstance, computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import DynamicForm from '@/components/fields/DynamicForm.vue'
import ConfirmDialog from '@atoms/misc/confirm-dialog.vue'
import { openDialog } from 'vue3-promise-dialog'
import Bonus from '@models/Bonus.js'
import BonusesService from '@services/BonusesService.js'

const confirmDelete = async (title, content) => {
  return await openDialog(ConfirmDialog, { title, content })
}

const object = ref({})
const structure = reactive({ list: [] })
const config = Bonus.getConfig()
const bonusId = ref(null)
const isLoading = ref(true)
const form = ref(null)

const store = useStore()
const operator = computed(() => ({ ...store.state.editPage.model }))

const route = useRoute()
const router = useRouter()

const { proxy } = getCurrentInstance()

const getMarketsData = () => {
  isLoading.value = true
  BonusesService.getOne(bonusId.value, {
    with: 'market,game_type,sportCategories,bettingTypes,oddsTypes,betting_features',
  })
    .then((response) => {
      object.value = response.data.result[0]
      structure.list = Bonus.getStructure(route?.params?.id).list
    })
    .finally(() => {
      isLoading.value = false
    })
}

const submit = async () => {
  form.value.isLoading = true

  try {
    const response = await form.value.process(object.value)
    const updateResponse = await BonusesService.update(response)

    if (updateResponse.data.success && updateResponse.data.status === 1) {
      const entityName = structure?.config?.name || 'Market Data'
      proxy.showSuccessMessage(`${entityName} updated`)
    } else if (updateResponse.data.messages) {
      proxy.showErrorMessages(updateResponse.data.messages)
    } else {
      proxy.showUnknownErrorMessage()
    }
  } catch (error) {
    console.log('Error during submission:', error)
    proxy.showUnknownErrorMessage()
  } finally {
    form.value.isLoading = false
  }
}

const remove = async () => {
  if (await confirmDelete('Warning', 'Are you sure you want to delete this item?')) {
    try {
      const response = await BonusesService.remove({ id: bonusId.value })

      if (response.data.messages) {
        proxy.showErrorMessages(response.data.messages)
      } else if (response.data.success) {
        const name = structure?.config?.name || 'Item'
        proxy.showSuccessMessage(`${name} removed`)
        router.push(`/operators/${route.params.id}/markets-data`)
      } else {
        proxy.showErrorMessages(['Unexpected response from server'])
      }
    } catch (error) {
      console.error('Error in remove:', error)
      proxy.showUnknownErrorMessage(error)
    }
  }
}

watch(
  () => route.params.marketId,
  (newVal) => {
    if (newVal) {
      bonusId.value = newVal
      getMarketsData()
    }
  },
  { immediate: true }
)
</script>
