<template>
  <node-view-wrapper v-bind="node.attrs" as="td">
    <node-view-content />
  </node-view-wrapper>
</template>

<script>
import { nodeViewProps, NodeViewWrapper, NodeViewContent } from '@tiptap/vue-3'

export default {
  components: {
    NodeViewWrapper,
    NodeViewContent,
  },
  props: nodeViewProps,
  mounted() {
    this.$refs.tableData?.$el.addEventListener('click', this.highlightCell)
  },
  beforeUnmount() {
    this.$refs.tableData?.$el.removeEventListener('click', this.highlightCell)
  },
  methods: {
    highlightCell() {
      const sel = getSelection().toString()
      if (sel.length) {
        this.$store.commit('tiptap/setSelectedText', sel)
      }
      if (!sel) {
        this.$store.commit('tiptap/setTableSelectedHeader', false)
      }
    },
  },
}
</script>
