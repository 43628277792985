<template>
  <div>
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-header d-flex flex-wrap justify-content-between">
            <h3 class="flex-grow-1">Search Engine Optimization</h3>

            <div v-if="confluenceSEOIds.search_engine_optimization_id">
              <span
                @click="openModalInformation(confluenceSEOIds.search_engine_optimization_id)"
                class="btn btn-outline-info btn-md"
              >
                <i class="uil uil-info-circle"></i>
                Help
              </span>
            </div>

            <hr class="hr-fat mb-2" />
          </div>

          <div class="card-body multi-column-form" v-if="page">
            <div class="row">
              <div class="col">
                <base-input
                  label="Meta title"
                  v-model="page.meta_title"
                  :show-placeholder-as-value="this.page.template.meta_title ? true : false"
                  :placeholder="page.template.meta_title"
                  :help-text="eventMetaTitleHelpText"
                />
              </div>

              <div class="col">
                <google-width-info
                  v-model="titleWidth"
                  :relationalData="relationalData"
                  :lang="page.market?.language_code"
                  :meta="activeMetaTitle"
                  @formatMeta="titleFormated"
                  :maxCharacters="maxTitleCharacters"
                  :maxWidth="maxTitleWidth"
                  :fontSize="20"
                />
              </div>
            </div>

            <div class="row">
              <div class="col">
                <base-input
                  label="Meta description"
                  v-model="page.meta_description"
                  :show-placeholder-as-value="this.page.template.meta_description ? true : false"
                  :placeholder="page.template.meta_description"
                />
              </div>

              <div class="col">
                <google-width-info
                  v-model="descriptionWidth"
                  :relationalData="relationalData"
                  :lang="page.market?.language_code"
                  :meta="activeMetaDescription"
                  @formatMeta="descriptionFormated"
                  :maxCharacters="maxDescriptionCharacters"
                  :maxWidth="maxDescriptionWidth"
                  :fontSize="14"
                />
              </div>
            </div>

            <div class="row">
              <div class="col">
                <label>Preview snippet</label>

                <div class="card">
                  <div class="card-body p-3 border" v-if="page">
                    <h4 :style="{ color: '#12c' }" class="mb-0">{{ seoTitle }}</h4>

                    <p :style="{ color: '#093' }" class="mb-0">{{ page.full_url }}</p>

                    <p>{{ seoDescription }}</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="row" v-if="page.site_id === 3">
              <div class="col-6">
                <base-checkbox
                  label="Use hard coded breadcrumbs (CTO only)"
                  v-model="page.hard_coded_breadcrumbs"
                />
              </div>
            </div>

            <div class="row">
              <div class="col">
                <model-select
                  model="page"
                  custom-label="Canonical URL"
                  :parameters="{
                    site_id: page.site_id,
                    market_id: page.market_id,
                    where_key_not: page.id,
                  }"
                  modal-size-class="modal-lg"
                  :value="page.canonical_url_id"
                  ref="modelSelect"
                  :valueLabel="page.valueLabel"
                  @valuePicked="(value, valueLabel) => canonicalChange(value, valueLabel)"
                  helpText="Canonical URL will point the search engines to the preferred version of the same page"
                />
              </div>

              <div class="col-6">
                <base-select
                  label="Region Code"
                  :path="`hercules/globals/countries`"
                  :clearable="true"
                  option-label-key="code"
                  trackBy="code"
                  v-model="page.region_code"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <base-tag
                  label="SEO Keywords"
                  v-model="page.seo_keywords"
                  :placeholder="'Type here to add a new keyword and press enter button'"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="card">
          <div
            class="card-header justify-content-between align-items-center cursor-pointer"
            @click="collapse('social')"
          >
            <h3>Social</h3>
            <div>
              <i
                :class="collapsedSections.social ? 'uil uil-angle-up' : 'uil uil-angle-down'"
                class="cursor-pointer w-100"
              ></i>
            </div>
          </div>

          <div class="card-body" v-if="page && collapsedSections.social">
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <media
                    v-model="page.social_share_image_id"
                    label="Social sharing image"
                    :siteSpecific="true"
                    helpText="Recommended size is 1200x630px"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="card">
          <div
            class="card-header d-flex flex-wrap justify-content-between cursor-pointer"
            @click="collapse('robots')"
          >
            <h3>Robot options</h3>
            <div class="d-flex align-items-center">
              <div v-if="confluenceSEOIds.robots_id">
                <span
                  @click.stop="openModalInformation(confluenceSEOIds.robots_id)"
                  class="btn btn-outline-info btn-md popover-trigger"
                >
                  <i class="uil uil-info-circle"></i>
                  Help
                </span>
              </div>
              <div class="ml-2">
                <i
                  :class="collapsedSections.robots ? 'uil uil-angle-up' : 'uil uil-angle-down'"
                  class="cursor-pointer"
                ></i>
              </div>
            </div>
          </div>

          <div class="card-body" v-if="page && collapsedSections.robots">
            <div class="row">
              <div class="col">
                <base-radio
                  label="Index page"
                  :modelValue="page.page_index ? 'yes' : 'no index'"
                  :data="['yes', 'no index']"
                  @update:modelValue="(option) => (page.page_index = option.value == 'yes' ? 1 : 0)"
                  :inlineLayout="true"
                />
              </div>

              <div class="col">
                <base-radio
                  label="Follow links"
                  :modelValue="page.links_followed ? 'yes' : 'no follow'"
                  :data="['yes', 'no follow']"
                  @update:modelValue="(option) => (page.links_followed = option.value == 'yes' ? 1 : 0)"
                  :inlineLayout="true"
                />
              </div>
            </div>

            <div class="row">
              <div class="col">
                <base-radio
                  label="Index images"
                  :modelValue="page.images_index ? 'yes' : 'no image index'"
                  :data="['yes', 'no image index']"
                  @update:modelValue="(option) => (page.images_index = option.value == 'yes' ? 1 : 0)"
                  :inlineLayout="true"
                />
              </div>

              <div class="col">
                <base-radio
                  label="Show snippet"
                  :modelValue="page.show_snippet ? 'yes' : 'no snippet'"
                  :data="['yes', 'no snippet']"
                  @update:modelValue="(option) => (page.show_snippet = option.value == 'yes' ? 1 : 0)"
                  :inlineLayout="true"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <base-input
                  label="Meta robot tags"
                  v-model="page.meta_robots"
                  help-text="Legacy - will soon be removed"
                />
              </div>

              <div class="col-6">
                <base-select
                  label="Crawler location"
                  :path="`hercules/globals/markets?orderBy=label&ascending=1`"
                  :clearable="true"
                  trackBy="id"
                  v-model="page.crawler_location_market_id"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <page-hreflang
      v-if="page.site.page_hreflang_groups"
      :page="page"
      :readOnly="readOnly"
      :collapsed-sections="collapsedSections"
      @updated-date="updatedDate"
      @collapse="collapse('hreflang')"
    />

    <page-custom-hreflang
      v-if="page.site.page_hreflang_groups"
      :page="page"
      :readOnly="readOnly"
      :collapsed-sections="collapsedSections"
      @updated-date="updatedDate"
      @collapse="collapse('customHrefLang')"
    />

    <div class="row">
      <div class="col">
        <div class="card" v-if="page">
          <div
            class="card-header d-flex flex-wrap justify-content-between cursor-pointer"
            @click="collapse('jsonschema')"
          >
            <h3 class="m-0">JSON Schema</h3>

            <div class="d-flex align-items-center">
              <div v-if="confluenceSEOIds.json_schema_id">
                <span
                  @click.stop="openModalInformation(confluenceSEOIds.json_schema_id)"
                  class="btn btn-outline-info btn-md popover-trigger"
                >
                  <i class="uil uil-info-circle"></i>
                  Help
                </span>
              </div>
              <div class="ml-2">
                <i
                  :class="collapsedSections.jsonschema ? 'uil uil-angle-up' : 'uil uil-angle-down'"
                  class="cursor-pointer"
                ></i>
              </div>
            </div>
          </div>

          <div v-if="collapsedSections.jsonschema">
            <div class="card-body" :key="index" v-for="(schema, index) in schemas">
              <hr v-if="index !== 0" />

              <div class="row">
                <div class="col d-flex justify-content-between">
                  <base-checkbox
                    label="JSON Schema Enabled"
                    v-model="page.schemas[index].page_json_schema_active"
                    :value="page.schemas[index].page_json_schema_active"
                  />

                  <button
                    v-if="index !== 0"
                    type="button"
                    class="btn btn-danger"
                    @click="removeSchema(index)"
                  >
                    Remove
                  </button>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <base-code-text
                    label="JSON Schema"
                    :ref="'jsonSchema' + index"
                    @change="validateJSON"
                    :code="page.schemas[index].json_schema"
                    :index="index"
                    language="javascript"
                  />

                  <label class="text-success ml-1 d-flex" v-if="jsonValidated[index] === true">
                    <i class="uil uil-check-circle mr-1" />
                    JSON Schema is valid
                  </label>

                  <label class="text-danger ml-1 d-flex" v-else-if="jsonValidated[index] === false">
                    <i class="uil uil-exclamation-circle mr-1"></i>
                    JSON Schema is invalid
                  </label>
                </div>
              </div>
            </div>

            <div class="card-body">
              <button type="button" class="btn btn-primary w-14" @click="createNewSchema">+ Add</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <information-modal ref="informationModal" :id="confluenceId" />
  </div>
</template>

<script>
import BaseTag from '@atoms/fields/base-tag.vue'
import BaseInput from '@atoms/fields/base-input.vue'
import BaseCodeText from '@atoms/fields/base-code-text.vue'
import BaseCheckbox from '@atoms/fields/base-checkbox.vue'
import BaseSelect from '@/components/fields/base-select.vue'
import BaseRadio from '@atoms/fields/base-radio.vue'
import PageHreflang from '@organisms/cms/pages/hreflang.vue'
import PageCustomHreflang from '@organisms/cms/pages/custom-hreflang.vue'
import ModelSelect from '@molecules/fields/model-select/base-model-select.vue'
import GoogleWidthInfo from '@molecules/general/google-width-info.vue'
import Media from '@molecules/fields/media.vue'
import ConfirmDialog from '@atoms/misc/confirm-dialog.vue'
import { openDialog } from 'vue3-promise-dialog'
import confluenceIds from '@constants/confluence-ids.js'
import InformationModal from '@molecules/cms/modals/information-modal.vue'

const confirmDelete = async (title, content, actionButtonTitle) => {
  return await openDialog(ConfirmDialog, { title, content, actionButtonTitle })
}

export default {
  components: {
    BaseTag,
    BaseInput,
    BaseSelect,
    BaseCheckbox,
    BaseCodeText,
    ModelSelect,
    PageHreflang,
    PageCustomHreflang,
    BaseRadio,
    GoogleWidthInfo,
    Media,
    InformationModal,
  },
  props: {
    page: {
      type: Object,
      required: true,
    },
    relationalData: {
      type: Object,
      required: false,
    },
    readOnly: {
      default: false,
    },
  },
  data() {
    return {
      jsonValidated: [null],
      seoTitle: null,
      seoDescription: null,
      maxTitleCharacters: 60,
      temporaryMaxTitleCharacters: null,
      maxDescriptionCharacters: 160,
      temporaryMaxDescriptionCharacters: null,
      maxTitleWidth: 580,
      maxDescriptionWidth: 920,
      titleWidth: 0,
      confluenceId: 0,
      confluenceSEOIds: {
        search_engine_optimization_id: null,
        hreflang_id: null,
        robots_id: null,
        json_schema_id: null,
      },
      descriptionWidth: 0,
      schemas: [
        {
          page_json_schema_active: false,
          json_schema: '',
        },
      ],
      collapsedSections: {
        social: false,
        robots: false,
        hreflang: false,
        customHrefLang: false,
        jsonschema: false,
      },
    }
  },
  created() {
    if (this.page.schemas.length > 0) {
      this.schemas = this.page.schemas
      this.schemas.forEach((element, index) => {
        this.validateJSON(element.json_schema, index)
      })
    } else {
      this.page.schemas = this.schemas
    }
    this.confluenceSEOIds.search_engine_optimization_id =
      confluenceIds.pages.seo.search_engine_optimization.id
    this.confluenceSEOIds.robots_id = confluenceIds.pages.seo.robot_options.id
    this.confluenceSEOIds.json_schema_id = confluenceIds.pages.seo.json_schema.id
  },
  watch: {
    page: {
      handler: function () {
        if (this.page.schemas.length > 0) {
          this.schemas = this.page.schemas
          this.schemas.forEach((element, index) => {
            this.validateJSON(element.json_schema, index)
          })
        } else {
          this.page.schemas = this.schemas
        }
      },
      deep: true,
    },
  },
  computed: {
    eventMetaTitleHelpText() {
      if (this.page.template.short_name == 'event') {
        return 'Available variables: {home_team}, {away_team}, {sport}, {time} and {date}'
      }
      return ''
    },
    activeMetaTitle() {
      return this.page.meta_title ? this.page.meta_title : this.page.template.meta_title
    },
    activeMetaDescription() {
      return this.page.meta_description ? this.page.meta_description : this.page.template.meta_description
    },
  },
  methods: {
    collapse(section) {
      this.collapsedSections[section] = !this.collapsedSections[section]
    },
    openModalInformation(id) {
      this.confluenceId = id
      setTimeout(() => {
        this.$refs.informationModal.show()
      }, 200)
    },
    createNewSchema() {
      let schema = {
        page_json_schema_active: 0,
        json_schema: '',
      }

      this.page.schemas.push(schema)
      this.schemas = this.page.schemas
    },
    async removeSchema(index) {
      if (await confirmDelete('Warning', `Are you sure want to delete this json?`, 'Yes')) {
        this.page.schemas?.splice(index, 1)
        this.schemas = this.page.schemas
      }
    },
    updatedDate() {
      this.$emit('updated-date')
    },
    validateJSON(value, index) {
      try {
        this.page.schemas[index].json_schema = value
        if (this.page.schemas[index].json_schema && this.page.schemas[index].json_schema.length > 0) {
          JSON.parse(this.page.schemas[index].json_schema)
          this.jsonValidated[index] = true
        } else {
          this.jsonValidated[index] = null
        }
      } catch (e) {
        this.jsonValidated[index] = false
      }
    },
    canonicalChange(value, valueLabel) {
      this.page.canonical_url_id = value
      this.page.valueLabel = valueLabel
      this.$forceUpdate()
    },
    subStringSeoMeta(value, maxCharacters) {
      let customText = value.substring(0, maxCharacters - 1).split(' ')
      let newCustomText = ''
      for (let i = 0; i < customText.length - 1; i++) {
        newCustomText += customText[i] + ' '
      }
      return newCustomText + '...'
    },
    titleFormated(value) {
      if (value.length <= this.maxTitleCharacters && this.titleWidth <= this.maxTitleWidth) {
        this.seoTitle = value
        this.temporaryMaxTitleCharacters = null
      } else {
        if (value.length <= this.maxTitleCharacters && this.temporaryMaxTitleCharacters == null) {
          this.temporaryMaxTitleCharacters = value.length
        }
        this.seoTitle = this.subStringSeoMeta(
          value,
          this.temporaryMaxTitleCharacters ? this.temporaryMaxTitleCharacters : this.maxTitleCharacters
        )
      }
    },
    descriptionFormated(value) {
      if (
        value.length <= this.maxDescriptionCharacters &&
        this.descriptionWidth <= this.maxDescriptionWidth
      ) {
        this.seoDescription = value
        this.temporaryMaxDescriptionCharacters = null
      } else {
        if (value.length <= this.maxDescriptionCharacters && this.temporaryMaxDescriptionCharacters == null) {
          this.temporaryMaxDescriptionCharacters = value.length
        }
        this.seoDescription = this.subStringSeoMeta(
          value,
          this.temporaryMaxDescriptionCharacters
            ? this.temporaryMaxDescriptionCharacters
            : this.maxDescriptionCharacters
        )
      }
    },
  },
}
</script>
