<template>
  <div :class="wrapperClass" :hidden="type === 'hidden'">
    <label v-if="label">{{ $prettyLabels(label) }}</label>
    <span v-if="required">&nbsp;*&nbsp;</span>

    <template v-if="notifiedByField && !$parent.object[notifiedByField.field]">
      <span class="text-muted ml-2">{{ notifiedByField.label }}</span>
    </template>

    <div class="dropdown" v-if="options.length > 0 || !loadOnMount">
      <input
        type="text"
        :disabled="disabled || (notifiedByField && !$parent.object[notifiedByField.field])"
        @click="dropdownClicked"
        class="form-control"
        v-debounce:200ms="valueChanged"
        v-model="activeOptionLabel"
        :placeholder="placeholderLabel ? placeholderLabel : placeholder"
        ref="dropdownInput"
        @blur="hideDropdown"
        @keydown.up.exact.prevent="focusPrevious(true)"
        @keydown.down.exact.prevent="focusNext(true)"
        @keypress.enter.exact.prevent="selectItem"
        @keyup.esc.exact.prevent="$parent.formType === 'edit' && hideDropdown()"
      />

      <span v-if="canBeCleared" @click="clearInput">
        <i
          class="uil uil-multiply position-absolute p-2 mb-1 clickable right-0 bottom-0"
          style="top: 5px"
        ></i>
      </span>

      <div class="dropdown-menu list-container" ref="dropdown-list">
        <div @click="clearSelection" v-if="showAllOption">All</div>

        <!-- Virtual list usage -->
        <virtual-list
          class="dropdown-item"
          :style="[
            staticStyles,
            dropdownHeight !== null ? { 'max-height': dropdownHeight + 'px', 'overflow-y': 'auto' } : {},
          ]"
          :data-sources="optionItems"
          :data-key="'id'"
          :data-component="activeOption"
          :extra-props="{
            dataFromParent: data,
            trackBy,
            pointer,
            optionLabelKey,
            allowNullOption,
            removeActiveText,
            extraOptionLabelKey,
          }"
          :wrap-class="'wrapper-items'"
          :footer-class="'footer-items'"
          :lengthOfOptions="lengthOfOptions"
          :lengthOfItems="optionItems.length"
        />

        <div v-if="!customOptions && optionItems.length === 0" class="virtual-list-footer">
          <span v-if="optionItems.length === 0 && !loading">No matching records</span>
          <span v-else-if="loading">Loading...</span>
        </div>
      </div>
    </div>

    <div v-else class="form-control text-muted">
      <template v-if="loading">
        <span>Loading ...</span>
      </template>
      <template v-else>
        <span>No {{ noOptionsPlaceholder }} available</span>
        <span v-if="noOptionsLink">
          , click
          <a :href="noOptionsLink" target="_blank">here</a>
          to add one
        </span>
      </template>
    </div>

    <small v-if="helpText" class="form-text text-muted">{{ helpText }}</small>
  </div>
</template>

<script>
import { markRaw } from 'vue'
import GeneralService from '../../services/GeneralService'
import activeOption from './vue-scroll-component/scroll-item.vue'

export default {
  name: 'base-select-virtual',
  inject: [],
  props: {
    removeActiveText: { type: Boolean, default: false },
    wrapperClass: { default: () => 'form-group position-relative' },
    capitalizeLabel: { type: Boolean, default: true },
    clearable: { type: Boolean, default: () => false },
    clearableIfOneOption: { type: Boolean, default: () => true },
    disabled: { type: Boolean, default: () => false },
    parameters: { type: Object, default: () => ({}) },
    placeholder: { type: String, default: 'Select an option' },
    label: { type: String },
    helpText: { type: String },
    optionLabelKey: { type: String, default: 'label' },
    modelValue: { default: null },
    customOptions: { default: null },
    readOnly: { default: () => false },
    required: { type: Boolean, default: false },
    selectFirstAfterFetch: { type: Boolean, default: false },
    selectFirstIfOneResult: { type: Boolean, default: false },
    loadOnMount: { type: Boolean, default: true },
    path: { type: String, default: null },
    emitOption: { type: String, default: null },
    noOptionsPlaceholder: { type: String, default: 'options' },
    noOptionsLink: { type: String },
    trackBy: { type: String, default: 'id' },
    showAllOption: { type: Boolean, default: false },
    notifiedByField: { type: Object, default: () => null },
    allowNullOption: { type: Boolean, default: false },
    type: { type: String, default: 'list-one' },
    maxOptions: { type: Boolean, default: false },
    searchBy: { type: String, default: null },
    extraOptionLabelKey: { type: String, default: null },
  },
  data() {
    return {
      data:
        typeof this.modelValue === 'object' && this.modelValue !== null
          ? this.modelValue[this.trackBy]
          : this.modelValue,
      options: [],
      loading: true,
      optionItems: [],
      activeOptionLabel: '',
      placeholderLabel: '',
      pointer: 0,
      focusedIndex: 0,
      activeOption: markRaw(activeOption),
      search: '',
      dynamicHeight: null,
      staticStyles: {
        maxHeight: '300px',
        overflowY: 'auto',
        zIndex: 9999,
      },
    }
  },
  computed: {
    dropdownHeight() {
      return this.dynamicHeight > 0 ? this.dynamicHeight - 52 : null
    },
    canBeCleared() {
      if (!this.clearable || !this.data) return false
      if (this.options.length === 1) return this.clearableIfOneOption
      return true
    },
    lengthOfOptions() {
      return this.optionItems.length <= 7 ? this.optionItems.length : 7
    },
  },
  provide() {
    return {
      onChangeItem: this.onChange,
    }
  },
  created() {
    if (this.customOptions) {
      this.options = this.customOptions
      this.optionItems = [...this.customOptions]
      this.updateActiveOptionLabel()
      this.loading = false
    } else if (
      (this.path && this.loadOnMount) ||
      this.data ||
      (this.notifiedByField &&
        this.$parent &&
        this.$parent.$parent &&
        this.$parent.$parent.object &&
        this.$parent.$parent.object[this.notifiedByField.field])
    ) {
      this.getOptions()
    }

    if (typeof this.modelValue === 'object' && this.modelValue !== null) {
      this.$emit('update:modelValue', this.data, this.emitOption ? this.getEmittedOption(this.data) : '')
    }
  },
  watch: {
    path(newValue, oldValue) {
      // Example condition for re-fetching if needed
      if (this.path && newValue !== oldValue && this.path.startsWith('koala/v1')) {
        this.getOptions()
      }
    },
    modelValue() {
      this.data =
        typeof this.modelValue === 'object' && this.modelValue !== null
          ? this.modelValue[this.trackBy]
          : this.modelValue
      this.updateActiveOptionLabel()
    },
    customOptions(newVal) {
      if (newVal) {
        this.options = [...newVal]
        this.optionItems = [...newVal]
        this.updateActiveOptionLabel()
        this.loading = false
      }
    },
    options() {
      this.optionItems = this.options
      if (this.optionItems.length > 0) {
        if (this.pointer !== (this.optionItems[0] && this.optionItems[0][this.trackBy])) {
          this.focusItem()
        }
      }
    },
    activeOptionLabel() {
      if (!this.searchBy && this.options) {
        const searchLower = this.activeOptionLabel
          ? this.activeOptionLabel.toLowerCase().replace(/:/g, '')
          : ''
        if (searchLower) {
          this.optionItems = this.options
            .filter((item) => {
              let itemLabel = ''
              if (typeof item === 'object' && item !== null) {
                const baseLabel = item[this.optionLabelKey]
                  ? item[this.optionLabelKey].toString().toLowerCase()
                  : ''
                const extraLabel =
                  this.extraOptionLabelKey && item[this.extraOptionLabelKey]
                    ? item[this.extraOptionLabelKey].toLowerCase() + ' > '
                    : ''
                itemLabel = (extraLabel + baseLabel).replace(/:/g, '')
              } else {
                itemLabel = String(item).toLowerCase().replace(/:/g, '')
              }
              return itemLabel.includes(searchLower)
            })
            .sort((a, b) => {
              const getLabel = (item) => {
                if (typeof item === 'object' && item !== null) {
                  const baseLabel = item[this.optionLabelKey]
                    ? item[this.optionLabelKey].toString().toLowerCase()
                    : ''
                  const extraLabel =
                    this.extraOptionLabelKey && item[this.extraOptionLabelKey]
                      ? item[this.extraOptionLabelKey].toLowerCase() + ' > '
                      : ''
                  return extraLabel + baseLabel
                }
                return typeof item === 'string' ? item.toLowerCase() : ''
              }

              const labelA = getLabel(a)
              const labelB = getLabel(b)

              const startsWithA = labelA.startsWith(searchLower)
              const startsWithB = labelB.startsWith(searchLower)

              if (startsWithA && !startsWithB) return -1
              if (!startsWithA && startsWithB) return 1
              return labelA.localeCompare(labelB)
            })
          this.activeOptionLabel = this.prettifyLabels(this.activeOptionLabel)
        } else {
          this.optionItems = this.options ? [...this.options] : []
        }
      }
    },
  },
  methods: {
    valueChanged(value) {
      if (this.searchBy) {
        this.search = value || ''
        if (this.search === '') {
          if (this.customOptions) {
            this.options = [...this.customOptions]
            this.optionItems = [...this.customOptions]
          } else {
            this.getOptions()
          }
        } else {
          if (this.customOptions) {
            const searchLower = this.search.toLowerCase()
            this.optionItems = this.customOptions.filter((item) => {
              const optionLabel =
                typeof item === 'object' && item !== null && item[this.optionLabelKey]
                  ? item[this.optionLabelKey].toString().toLowerCase()
                  : String(item).toLowerCase()
              return optionLabel.includes(searchLower)
            })
          } else {
            this.getOptionsBySearch()
          }
        }
      }
    },
    clearSelection() {
      this.$emit('update:modelValue', null)
      this.$emit('isValueClearedListener')
    },
    clearInput() {
      this.placeholderLabel = ''
      this.$emit('update:modelValue', null)
      this.activeOptionLabel = ''
      this.optionItems = this.customOptions ? [...this.customOptions] : [...this.options]
    },
    updateActiveOptionLabel() {
      if (this.data !== null && this.data !== undefined && this.options.length > 0) {
        const valueToFind = !isNaN(this.data) ? parseInt(this.data) : this.data
        let option = this.options.find((item) => {
          if (typeof item === 'object' && item !== null) {
            return item[this.trackBy] === valueToFind
          }
          return item === valueToFind
        })

        let optionLabel
        if (typeof option === 'object' && option !== null) {
          if (this.extraOptionLabelKey) {
            optionLabel = option[this.extraOptionLabelKey] + ' > ' + option[this.optionLabelKey]
          } else {
            optionLabel = option[this.optionLabelKey]
          }
        } else {
          optionLabel = option
        }

        this.activeOptionLabel = this.capitalizeLabel ? this.capitalize(optionLabel) : optionLabel
      } else {
        this.activeOptionLabel = ''
        this.placeholderLabel = ''
      }
      this.pointer = this.data
      this.focusedIndex = this.optionItems.findIndex((item) => {
        if (typeof item === 'object' && item !== null) {
          return item[this.trackBy] === this.data
        }
        return item === this.data
      })
    },
    dropdownClicked() {
      if (this.path && !this.loadOnMount && this.options.length === 0) {
        this.getOptions()
      }

      if (this.activeOptionLabel !== '') {
        this.placeholderLabel = this.activeOptionLabel
      }
      this.activeOptionLabel = ''
      this.$refs.dropdownInput && this.$refs.dropdownInput.focus()
      this.getDropdownHeight()
    },
    getDropdownHeight() {
      const dropdownListRect =
        this.$refs['dropdown-list'] && this.$refs['dropdown-list'].getBoundingClientRect()
      const cardBody = document.querySelector('.card-body')
      const tableRect = cardBody ? cardBody.getBoundingClientRect() : { bottom: window.innerHeight }

      if (!dropdownListRect) return

      const spaceBelowViewport = window.innerHeight - dropdownListRect.top
      const spaceBelowTable = tableRect.bottom - dropdownListRect.top
      const maxDropdownHeight = Math.min(spaceBelowViewport, spaceBelowTable)

      this.dynamicHeight = maxDropdownHeight > 0 ? maxDropdownHeight : 0
    },
    onChange(option) {
      this.$refs.dropdownInput && this.$refs.dropdownInput.blur()

      const optionValue = typeof option === 'object' && option !== null ? option[this.trackBy] : option
      if (optionValue === this.data && this.allowNullOption) {
        this.data = null
        this.activeOptionLabel = ''
      } else {
        this.data = optionValue
      }

      if (this.searchBy) {
        this.search = ''
        this.getOptions()
      }

      this.$emit('update:modelValue', this.data, this.emitOption ? this.getEmittedOption(this.data) : '')
    },
    getEmittedOption(value) {
      const matchedItem = this.options.find((item) => {
        if (typeof item === 'object' && item !== null) {
          return item[this.trackBy] === value
        }
        return item === value
      })

      return matchedItem && this.emitOption && typeof matchedItem === 'object'
        ? matchedItem[this.emitOption]
        : ''
    },
    getOptions(selectFirst = false) {
      let data = {}
      if (this.notifiedByField && this.$parent && this.$parent.$parent && this.$parent.$parent.object) {
        data[this.notifiedByField.field] = this.$parent.$parent.object[this.notifiedByField.field]
      }

      data = {
        ...data,
        ...(this.parameters ? this.parameters : {}),
        ...(this.searchBy && this.search.length ? { [this.searchBy]: this.search } : {}),
      }

      this.loading = true
      return new Promise((resolve) => {
        GeneralService.fetchItems(this.path, data).then((response) => {
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages)
            this.loading = false
            resolve([])
            return
          }

          const rawResult = response.data.result || []

          if (!this.emitOption) {
            this.options = rawResult
              .filter((item) => this.resolve(this.optionLabelKey, item))
              .map((item) => {
                const baseObj = {
                  id: item[this.trackBy],
                  [this.trackBy]: item[this.trackBy],
                  [this.optionLabelKey]: this.resolve(this.optionLabelKey, item),
                }
                if (this.extraOptionLabelKey) {
                  baseObj[this.extraOptionLabelKey] = this.resolve(this.extraOptionLabelKey, item)
                }
                return baseObj
              })
          } else {
            this.options = rawResult.map((item) => {
              const baseObj = {
                id: item[this.trackBy],
                [this.trackBy]: item[this.trackBy],
                [this.optionLabelKey]: this.resolve(this.optionLabelKey, item),
                [this.emitOption]: item[this.emitOption],
              }
              if (this.extraOptionLabelKey) {
                baseObj[this.extraOptionLabelKey] = this.resolve(this.extraOptionLabelKey, item)
              }
              return baseObj
            })
          }

          this.optionItems = [...this.options]

          this.$nextTick(() => {
            if (
              selectFirst ||
              this.selectFirstAfterFetch ||
              (this.selectFirstIfOneResult && this.options.length === 1)
            ) {
              this.selectFirstOption()
            }
          })

          if (this.loading) {
            this.updateActiveOptionLabel()
          }
          this.loading = false
          resolve(this.options)
        })
      })
    },
    getOptionsBySearch() {
      let data = {}
      if (this.notifiedByField && this.$parent && this.$parent.$parent && this.$parent.$parent.object) {
        data[this.notifiedByField.field] = this.$parent.$parent.object[this.notifiedByField.field]
      }

      data = {
        ...data,
        ...(this.parameters ? this.parameters : {}),
        ...(this.searchBy && this.search.length ? { [this.searchBy]: this.search } : {}),
      }

      GeneralService.fetchItems(this.path, data).then((response) => {
        if (response.data.messages) {
          this.showErrorMessages(response.data.messages)
          return
        }

        const rawResult = response.data.result || []
        if (!this.emitOption) {
          this.options = rawResult
            .filter((item) => this.resolve(this.optionLabelKey, item))
            .map((item) => {
              const baseObj = {
                id: item[this.trackBy],
                [this.trackBy]: item[this.trackBy],
                [this.optionLabelKey]: this.resolve(this.optionLabelKey, item),
              }
              if (this.extraOptionLabelKey) {
                baseObj[this.extraOptionLabelKey] = this.resolve(this.extraOptionLabelKey, item)
              }
              return baseObj
            })
        } else {
          this.options = rawResult.map((item) => {
            const baseObj = {
              id: item[this.trackBy],
              [this.trackBy]: item[this.trackBy],
              [this.optionLabelKey]: this.resolve(this.optionLabelKey, item),
              [this.emitOption]: item[this.emitOption],
            }
            if (this.extraOptionLabelKey) {
              baseObj[this.extraOptionLabelKey] = this.resolve(this.extraOptionLabelKey, item)
            }
            return baseObj
          })
        }
        this.optionItems = [...this.options]
      })
    },
    setActiveOption() {
      if (this.trackBy === 'id') {
        GeneralService.fetchItem(this.path.split('?')[0], this.data, {}).then((response) => {
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages)
            return
          }
          this.setOptions(response.data.result)
          this.updateActiveOptionLabel()
        })
        return
      }

      const data = {}
      data[this.trackBy] = this.data
      GeneralService.fetchItems(this.path.split('?')[0], data).then((response) => {
        if (response.data.messages) {
          this.showErrorMessages(response.data.messages)
          return
        }
        this.setOptions(response.data.result)
        this.updateActiveOptionLabel()
      })
    },
    setOptions(data) {
      if (!this.emitOption) {
        this.options = data
          .filter((item) => this.resolve(this.optionLabelKey, item))
          .map((item) => {
            const baseObj = {
              id: item[this.trackBy],
              [this.trackBy]: item[this.trackBy],
              [this.optionLabelKey]: this.resolve(this.optionLabelKey, item),
            }
            if (this.extraOptionLabelKey) {
              baseObj[this.extraOptionLabelKey] = this.resolve(this.extraOptionLabelKey, item)
            }
            return baseObj
          })
      } else {
        this.options = data.map((item) => {
          const baseObj = {
            id: item[this.trackBy],
            [this.trackBy]: item[this.trackBy],
            [this.optionLabelKey]: this.resolve(this.optionLabelKey, item),
            [this.emitOption]: item[this.emitOption],
          }
          if (this.extraOptionLabelKey) {
            baseObj[this.extraOptionLabelKey] = this.resolve(this.extraOptionLabelKey, item)
          }
          return baseObj
        })
      }
      this.optionItems = [...this.options]
    },
    resolve(path, obj) {
      return path.split('.').reduce((prev, curr) => {
        return prev ? prev[curr] : null
      }, obj || self)
    },
    selectFirstOption() {
      if (this.options && this.options.length) {
        this.$emit(
          'update:modelValue',
          this.options[0][this.trackBy],
          this.emitOption ? this.options[0][this.emitOption] : ''
        )
      } else {
        this.$emit('update:modelValue', null)
      }
    },
    focusNext(isArrowKey) {
      if (this.focusedIndex < this.optionItems.length - 1) {
        this.focusedIndex += 1
        if (isArrowKey) {
          this.focusItem()
        }
      }
    },
    focusPrevious(isArrowKey) {
      if (this.focusedIndex > 0) {
        this.focusedIndex -= 1
        if (isArrowKey) {
          this.focusItem()
        }
      }
    },
    focusItem() {
      if (this.optionItems.length !== 0) {
        const current = this.optionItems[this.focusedIndex]
        this.pointer = typeof current === 'object' && current !== null ? current[this.trackBy] : current
      } else {
        this.pointer = null
      }
    },
    selectItem() {
      if (this.optionItems.length > 0 && this.optionItems[this.focusedIndex]) {
        this.onChange(this.optionItems[this.focusedIndex])
      }
    },
    hideDropdown() {
      this.$refs.dropdownInput && this.$refs.dropdownInput.blur()
      if (this.data === null) {
        this.focusedIndex = 0
        this.focusItem()
      }
      this.valueChanged('')
      setTimeout(() => {
        this.updateActiveOptionLabel()
      }, 300)
    },
    prettifyLabels(label) {
      return this.capitalizeLabel && label ? this.$prettyLabels(label) : label
    },
    capitalize(str) {
      return typeof str === 'string' && str.length > 0 ? str.charAt(0).toUpperCase() + str.slice(1) : str
    },
    showErrorMessages(messages) {
      console.error('Error messages:', messages)
    },
  },
}
</script>
<style lang="scss">
.wrapper-items {
  background: white !important;
  color: #6e6e84 !important;
}

.list-container {
  max-height: 350px;
  overflow-y: hidden;
  overflow-x: hidden;
  padding: 0 !important;
  margin: 5rem;
  list-style: none;
  display: flex;
  justify-content: space-between;
  .dropdown-item {
    padding: 0 !important;
    bottom: 0;
  }
}
</style>
<style scoped>
.dropdown .dropdown-menu {
  width: 100%;
  transition: none !important;
  transition-delay: unset !important;
}

.dropdown .dropdown-menu.show {
  transform: scaleY(1);
}

.list-container::-webkit-scrollbar {
  width: 0;
}

.dropdown input {
  padding: 0 14px;
}

.dropdown .dropdown-menu li {
  text-transform: none;
}

.dropdown > input.form-control {
  background-image: unset !important;
}

.dropdown > input.form-control::placeholder,
.dropdown > input.form-control:focus {
  color: #999 !important;
}

.multiselect__arrow {
  position: absolute;
  width: 40px;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 4px 8px;
  text-align: center;
  transition: transform 0.2s ease;
  display: flex;
}

.multiselect__arrow::before {
  display: inline-block;
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: '';
  transition: transform 0.2s ease;
  margin: auto;
}

.multiselect__arrow.active::before {
  transform: rotate(180deg);
}

.absolute-clear-button {
  position: absolute;
  top: -25px;
  right: 4px;
}

.no-hover {
  color: #17182f !important;
  font-weight: 500 !important;
  /* font-size: 12px !important; */
  background: white;
  text-align: center;
  z-index: 99999;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
}
</style>
